import React from "react"
import { 
  Typography,
   Grid,
   ButtonGroup,
   Button
  } from "@material-ui/core"
import HOME_BANNER from "../assets/images/EIT_HOME_BANNER.jpg"
import AboutAIJson from "../data/aboutAI.json"

export default function AboutAI() {
  const [state] = React.useState({
    dataValues: AboutAIJson,
  });

  return (
    <div className="aboutAIContainer" >
      <Grid container xs={12} className="banner">
        <Grid item xs={12} sm={12}>
        
        <Typography className="aboutTitle fadePanel">
         
         <i className="line">
            Enterprise IT Managed Services
         </i>
     
     </Typography>
    
        <img  alt="Home Banner" src={ HOME_BANNER} style={{ width: "100%" }} />
        </Grid>
        <Grid item xs={12} sm={12}></Grid>
      </Grid>

      <Grid container xs={12} className="homeButtons"  >
        <Grid item xs={12}  className="marginCenter" >

            <ButtonGroup variant="contained"  >
              <Button 
              variant="contained"
              target="_blank"
              href="https://saiconline.sharepoint.us/teams/EnterpriseITDIFPortal/Shared%20Documents/Forms/AllItems.aspx?id=%2Fteams%2FEnterpriseITDIFPortal%2FShared%20Documents%2F101%20Offering%20Guides&viewid=f94c5bd4%2D7416%2D4f73%2D8f44%2D6c2f4d61ee03&CT=1731517894074&OR=OWA%2DNT%2DMail&CID=b4bd498a%2Db367%2Dcffd%2Da3f1%2Dcd66852eb1a4"
              >
                Offering Guide 
                </Button>
            
              <Button
                variant="contained"z
                target="_blank"
                href="https://saiconline.sharepoint.us/teams/EnterpriseITDIFPortal/Shared%20Documents/Forms/AllItems.aspx?id=%2Fteams%2FEnterpriseITDIFPortal%2FShared%20Documents%2FCustomer%20Presentations&viewid=f94c5bd4%2D7416%2D4f73%2D8f44%2D6c2f4d61ee03&CT=1731517971374&OR=OWA%2DNT%2DMail&CID=b272320f%2Df217%2D3bfe%2Df9fa%2D1119af43b987"
              >
                Customer Brief
              </Button>

              <Button 
              variant="contained"
              target="_blank"
              href="https://saiconline.sharepoint.us/teams/EnterpriseITDIFPortal/Shared%20Documents/Forms/AllItems.aspx?id=%2Fteams%2FEnterpriseITDIFPortal%2FShared%20Documents%2F201%20Solution%20Guides&viewid=f94c5bd4%2D7416%2D4f73%2D8f44%2D6c2f4d61ee03&CT=1731518226027&OR=OWA%2DNT%2DMail&CID=73d87e03%2Daf4e%2De1f6%2Dff75%2D847f579e98a6"
              >
                Solution Guides
                </Button>
            
              <Button
                variant="contained"z
                target="_blank"
                href="https://saiconline.sharepoint.us/teams/EnterpriseITDIFPortal/Shared%20Documents/Forms/AllItems.aspx?noAuthRedirect=1&CT=1731518251666&OR=OWA%2DNT%2DMail&CID=9d75dd72%2Da02f%2Dc67d%2D5cf8%2D04b56d9302c5"
              >
                EIT Collateral
              </Button>

            </ButtonGroup>
             
          </Grid>
      
      </Grid>

      <div className="spacer" />
      <div className="spacer" />
      <div className="spacer" />

      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className="introduction"
      >
        <Grid className="marginCenter" item xs={10} >
       
            <Typography variant="h2" className="subTitle">
             
                 Enterprise IT Managed Services
         
           </Typography>

        </Grid>

        <div className="spacer" />
        
        {state.dataValues.map((item) => {
          return (
            <Grid className="marginCenter" item xs={10} sm={10} md={10} lg={10} >
              <Typography  >
             {
              item.intro
             }
              </Typography>
            </Grid>
          )
        })}
        <div className="spacer" />
        <div className="spacer" />
        <div className="dividerShape" />

      </Grid>
    </div>
  );
}
